import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const sizes = {
  small: '68px',
  medium: '68px',
  large: '131px',
};

const getSize = (value) => {
  if (sizes[value]) return sizes[value];
  return value;
};

const backgrounds = {
  'AD Glossary': 'secondary',
  'AD vs. eczema': 'tertiary',
  ADCT: 'tertiary',
  Allergies: 'secondary',
  Bamboo: 'tertiary',
  Bug: 'tertiary',
  Chemicals: 'tertiary',
  Community: 'tertiary',
  Confidence: 'secondary',
  Conversation: 'tertiary',
  Diet: 'tertiary',
  Doctor: 'tertiary',
  'Dr Discussion Guide': 'tertiary',
  Dust_2: 'secondary',
  Dust_4: 'tertiary',
  Dust_5: 'secondary',
  Dust: 'secondary',
  Environment: 'secondary',
  'Finding Control': 'tertiary',
  Humidity: 'secondary',
  Hand: 'secondary',
  Initiatives: 'tertiary',
  'Mental Health 1': 'secondary',
  MerinoWool: 'tertiary',
  Pollen: 'secondary',
  Seasons: 'secondary',
  Severity: 'tertiary',
  Silk: 'secondary',
  Sleep: 'secondary',
  Stress: 'tertiary',
  'Stress 1': 'secondary',
  Sun: 'secondary',
  Symptoms: 'secondary',
  'Treatment Guide': 'tertiary',
  'Understanding AD': 'secondary',
  Pills: 'secondary',
  Atopic: 'secondary',
  'Different Skin Tones': 'secondary',
  Work: 'secondary',
};

const Wrapper = styled.div`
  position: relative;
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  flex-shrink: 0;
  margin: 0 auto;
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-large);
`;

const BackgroundCircle = styled.div`
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: ${({ backgroundColor }) => `var(--color-${backgroundColor})`};
  border-radius: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 160%;
  height: 160%;
  display: block;
  margin: 0 auto;
`;

const Graphic = ({
  name, alt, size, className,
}) => (
  <Wrapper size={size} className={className}>
    <BackgroundCircle backgroundColor={backgrounds[name] || 'primary'} />
    <Image src={`/graphics/${name}.svg`} alt={alt || name} />
  </Wrapper>
);

Graphic.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

Graphic.defaultProps = {
  size: 'large',
  alt: '',
  className: '',
};

export default Graphic;
