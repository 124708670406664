import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
  margin: 0 auto;
  aspect-ratio: 16/9;

  @media not screen and (min-width: 834px) {
    width: 100%;
  }
`;

const Video = styled.iframe`
  border: none;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
`;

const VideoEmbed = ({ src, width }) => (
  <Wrapper width={width}>
    <Video
      src={src}
      allow="encrypted-media; fullscreen"
    />
  </Wrapper>
);

VideoEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
};

VideoEmbed.defaultProps = {
  width: '100%',
};

export default VideoEmbed;
