import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const getMaxWidth = (size, maxWidth) => {
  if (maxWidth) return maxWidth;
  return (size === 'large') ? 500 : 400;
};

const Style = styled.img`
  max-width: ${({ size, maxWidth }) => `${getMaxWidth(size, maxWidth)}px`};
  display: block;
  margin: 0 auto;
  width: 100%;
`;

const SideImage = ({
  pageImages, src, alt, size, maxWidth, className,
}) => {
  if (pageImages && pageImages[src] && pageImages[src].childImageSharp) {
    const image = getImage(pageImages[src]);

    return (
      <Style
        as={GatsbyImage}
        image={image}
        alt={alt}
        size={size}
        $maxWidth={maxWidth}
        className={className}
      />
    );
  }
  try {
    if (!src) return null;

    return (
      <Style
        size={size}
        $maxWidth={maxWidth}
        src={require(`../../assets/image/${src}`).default}
        alt={alt}
        className={className}
      />
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return null;
  }
};

SideImage.propTypes = {
  /**
   * Key object pair of images used in a page
   */
  // eslint-disable-next-line react/forbid-prop-types
  pageImages: PropTypes.object.isRequired,
  /**
   * The source of the image inside of the assets/image directory.
   */
  src: PropTypes.string.isRequired,
  /**
   * Alt text for the image
   */
  alt: PropTypes.string.isRequired,
  /**
   * The size of the image
   */
  size: PropTypes.oneOf(['large', 'medium']),
  /**
   * Max width of the image to display,
   */
  maxWidth: PropTypes.number,
  /**
   * Optional class names for styling
   */
  className: PropTypes.string,
};

SideImage.defaultProps = {
  size: 'large',
  maxWidth: 0,
  className: '',
};

export default SideImage;
