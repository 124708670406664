import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Title, ModalContext, Icon } from '@lxlabs/change-ad-components';
import VideoEmbed from './videoEmbed';

const Style = styled.button`
  display: block;
  background-image: ${({ poster }) => `url('${require(`../../assets/image/${poster}`).default}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--default-transition);

  &:focus, &:hover {
    box-shadow: inset 0px 0px 0px 5px var(--color-secondary);
  }
`;

const VideoTitle = styled(Title)`
  position: absolute;
  top: var(--spacing-small);
  left: var(--spacing-small);
  color: var(--color-white);
  text-shadow: -1px -1px 8px rgb(0 0 0);
  font-family: 'Museo Sans';
`;

const ModalTitle = styled(Title)`
  color: var(--color-primary);
`;

const IconWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 84px;
  height: 84px;
  background-color: blue;
  border-radius: 84px;
  background-color: rgba(208, 196, 231, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: var(--color-primary);
    width: 40px;
    height: 40px;
    margin-left: 8px;
  }
`;

const PlayButton = () => (
  <IconWrapper>
    <Icon name="triangleRight" />
  </IconWrapper>
);

const ModalContent = ({ title, src }) => (
  <>
    <ModalTitle size="small" spacing={{ bottom: 'small' }}>{title}</ModalTitle>
    <VideoEmbed src={src} />
  </>
);

ModalContent.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const VideoPopup = ({ src, title, poster }) => {
  const { handleModal } = useContext(ModalContext);

  return (
    <Style
      aria-label={`Watch video - ${title}`}
      onClick={() => handleModal(<ModalContent title={title} src={src} />)}
      poster={poster}
    >
      <VideoTitle size="small">{title}</VideoTitle>
      <PlayButton />
    </Style>
  );
};

VideoPopup.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
};

export default VideoPopup;
