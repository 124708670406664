import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  width: 65px;
  height: 65px;
`;

/**
 * These are flags which will display as a .png instead of an .svg
 */
const pngImages = [
  'Spain',
];

const Flag = ({ name, alt }) => (
  <Image src={`/flags/${name}${pngImages.includes(name) ? '.png' : '.svg'}`} alt={alt || `Flag of ${name}`} />
);

Flag.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Flag.defaultProps = {
  alt: '',
};

export default Flag;
